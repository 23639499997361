footer {
    width: 100%;
    /* height: 290px; */
    background: #353a45;
    padding-top: 3rem;
    font-size: 1rem;
}

.copy-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid rgb(145, 145, 145, 0.5);
    padding: 0.9rem 0;
    color: rgb(145, 145, 145);
}

.copy-container span {
    font-size: 0.85rem;
}

.social-icons .icon {
    margin-left: 10px;
    cursor: pointer;
}

.social-icons .icon:hover {
    color: #fff;
}

.sections-container {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1.2rem;
    margin-bottom: 1.2rem;
}

.footer-list-container a {
    color: rgb(145, 145, 145);
    margin-bottom: 0.4rem;
}

.footer-list-container a:hover {
    color: rgb(224, 224, 224);
}

.footer-list-container h4 {
    font-size: 1.2rem;
    margin-bottom: 0.3rem;
    font-weight: 500;
}

.footer-list-container>ul>li {
    display: flex;
    flex-direction: column;
    font-size: 15px;
}

#contact li>span {
    margin-left: 10px;
    color: rgb(145, 145, 145);
}

.icons-contact-footer {
    color: rgb(175, 175, 175);
}

#contact li {
    min-width: 15rem;
    display: block;
    margin-bottom: 0.5rem;
}


/* ============ Media queries (Smal devices) =============== */
@media screen and (max-width: 900px) {
    .sections-container {
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1rem));
        width: 90%; 
    }
}