.tools-body {
    color: #353535;
    padding: 70px 0;
    min-height: calc(100vh - 300px - 350px);
}

.tools-container>p {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
}

.articles-tools {
    display: grid;
    gap: 2rem;
    grid-template-columns: 33% 63%;
    margin-top: 4rem;
    padding: 1.2rem 1rem;
    box-shadow: 0px 0px 4px #ccc ;
}

.logo-tool {
    width: 100%;
    height: 100%;
    background-color: #444b59;
    max-width: 512px;
    max-height: 300px;
}

.logo-tool img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}

.info-tool p {
    color: #444b59;
    font-size: 16px;
}

.info-tool > p {
    margin-bottom: 1rem;
    font-size: 16px;
}

.info-tool > ul {
    margin-bottom: 1.5rem;
}

.info-tool > a {
    border-radius: 4px;
    background-color: #6542e0;
    font-size: 16px;
    padding: 8px 18px;
    color: #fff;
    transition: all 200ms ease;
}

.info-tool > a:hover{
    background-color: #3d2887;
}

.icon-article-funtion {
    color: #6542e0;
    font-size: 1.3rem;
    margin-right: 0.8rem;
    min-width: 1.5rem;
}

.feat-list {
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 886px) {
    .feat-list {
        align-items: baseline;
    }
}

@media screen and (max-width: 720px) {
    

    .articles-tools {
        grid-template-columns: 1fr;
    }
}