

.about-home {
    padding: 70px 0;
    margin: 0;
    background-color: white;
}

.about-home h2{
    color: #424141;
    margin-bottom: 30px;
}


.about-container {
    display: grid;
    grid-template-columns: 40% 60%;
    gap: 50px;
}


.about-container-left img {
    width: 100%;
    height: 100%; 
    object-fit: cover;
    max-height: 400px;
    min-width: 270px;
}

.about-container-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-container-right p{
    margin-bottom: 20px;
}

@media screen and (max-width: 753px) {
    .about-container {
        grid-template-columns: 1fr;
    }
}

 