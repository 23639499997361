.services-screen-container {
    color: #444b59;
    padding: 25px 0;
}

.art-sect {
    padding: 38px 0;
    margin-top: 3.5rem;
}

.grid-art {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
}

.image-art {
    width: 100%;
    height: 100%;
}

.image-art img {
    width: 100%;
    object-fit: cover;
    position: sticky;
    top: 1rem;
}

/* ########### TITLE ########### */

.title-sector {
    margin-bottom: 2rem;
}

.title-sector::after {
    /* content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin-bottom: 2rem; */

    background: #d5f311;
    border-radius: 6px;
    content: "";
    height: 0.5rem;
    width: 1.7rem;
    display: inline-block;
    position: relative;
    margin-left: 0.6rem;
}
/* ########### END TITLE ########### */

.art-content h3 {
    margin-bottom: 0.7rem;
}

.art-content p {
    margin-bottom: 1.5rem;
}

.art-content ul li {
    list-style-type: disc;
    margin-left: 1rem;
    font-size: 18px;
}

.art-content ul li::marker {
    margin-right: 2rem;
    color: #abc022;
}

@media screen and (max-width: 678px) {
    .inverted {
        grid-row: 1;
    }
}