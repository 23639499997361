
.aboutUs-container {
    color: #424141;
    padding: 70px 0;
    min-height: calc(100vh - 300px - 350px);
}

.aboutUs-container h2 {
    font-size: 2rem;
    margin-bottom: 0.6rem;
}

.aboutUs-container p {
    margin-bottom: 1.2rem;
}

.aboutUs-container ul {
    margin-bottom: 1.5rem;
}

.aboutUs-container ul li {
    list-style: inside;
    
}

.aboutUs-container ul li::marker {
    margin-right: 2rem;
    color: #bbc022;
}