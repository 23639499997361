
input,
textarea {
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 100%;
    resize: none;
    padding: 7px 12px;
    font-size: 14px;
    font-family: sans-serif, Arial, Helvetica;
    color: #555;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    transition: all 0.3s ease;
}

input {
    height: 36px;
}

input:focus,
textarea:focus-within {
    outline: 0;
    border-color: #6542e0;
    box-shadow: 0 0 2px  #6542e0 inset;
}


.contact-body {
    padding: 70px 0;
    min-height: calc(100vh - 300px - 350px);

}

.contact-container {
    display: grid;
    grid-template-columns: 42% 1fr;
}

.info-container {
    background-color: #6542e0;
    padding: 3rem;
    height: 100%;
}

.info-container p {
    color: rgb(222, 220, 220);
    font-size: 1rem;
    margin: 1.5rem 0;
}

.contact-option {
   margin: 1rem 0;
}

.icons-contact {
    margin-right: 1rem;
    font-size: 1.2rem;
}

.form-container {
    color: #424141;
    padding: 3rem;
    height: 100%;
}

.form-container form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.6rem;
    align-items: flex-start;
}

form > button {
    margin-top: 0.8rem;
    background-color: #6542e0;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 3px;
}

@media screen and (max-width: 850px) {
    .contact-container {
        grid-template-columns: 1fr;
    }
}

/* ============ Media queries (Smal devices) =============== */
@media screen and (max-width: 850px) {
    .form-container {
        padding: 3rem 0;
    }

    .info-container {
        padding: 2rem 1rem;
        
    }
}