
.services-home {
    padding: 70px 0;
    margin: 0;
    background-color: #f2f7f8;
    color: #424141;
    text-align: center;
    
}

.services-home > p {
    margin-bottom: 40px;
} 

.services-container {
    display: grid;
    gap: 35px;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

.service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    box-shadow: 1px 0px 15px rgb(161, 161, 161);
}

.icons-container{
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #0f2e47;
    border-radius: 5px;
    height: 170px;
    overflow: hidden;
}

.icons {
    font-size: 80px;
    color: white;
}

.card-content {
    padding: 10px;
}

.card-content h3 {
    font-size: 18px;
    font-weight: 500;
    color: #333;
}

.card-content p {
    font-size: 14px;
    color: #707070;
    margin: 5px 0;
}

.card-content button {
    border: none;
    border-radius: 4px;
    font-size: 16px;
    color: #fff;
    padding: 10px 16px;
    background-color: #6542e0;
    margin: 14px;
    cursor: pointer;
}

.card-content button:hover {
    background-color: #4c3dbd;

}