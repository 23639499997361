.header-home {
    height: 500px;
    background: #444b59;
    
    /* prueba quitar */
    background-image: url(../../../assets/bridge-header-cuted.png);
    background-size: cover;
    width: 100%;
    position: relative;
}

.header-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.header-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 3rem;
    z-index: 11;
    
}

.header-container h1 {
    font-weight: 700;
    font-size: clamp(2rem, 6vw, 2.5rem);
    z-index: 1;
}

.header-container p {
    margin: 15px 0px 5px 0px;
    font-size: clamp(0.8rem, 6vw, 1.4rem);
    font-weight: 300;
    max-width: 90%;
    z-index: 1;
}

.typewriter {
    color: rgb(229, 229, 229);
    /* white-space: nowrap;  */
    /* overflow: hidden;  */
    /* animation: typing 2s steps(20); */
} 

/* @keyframes typing {
    0% { 
        width: 0;
        border-right: 2px solid;
    }

    50%, 70%, 94%{
        border-right: 2px solid;
    }
 
    100% { 
        width: 760px;
     }
} */


/* ####### CTA ######### */

.cta-contact {
    display: block;
    width: 133px;
    padding: 12px 22px 12px 22px;
    margin-top: 20px;
    background-color: #6542e0;
    color: white;
    border-radius: 4px;
    font-size: 17px;
    letter-spacing: 0.04em;
    border: 3px solid transparent;
    transition: 300ms all;
    z-index: 1;
}

.cta-contact:hover {
    background-color: transparent;
    border: 2px solid #6542e0;
    color: #7f65df;
    cursor: pointer;
}

@media screen and (max-width: 1035px) {
    .typewriter {
        animation: none;
        white-space: initial;
    }
}