.navbar {
    /* background: #444b59; */
    width: 100%;
    height: 68px;
    color: #fff;

    position: fixed;
    background-color: transparent;
    z-index: 10;
    transition: 0.2s all;
}

.active {
    background: #444b59;
    box-shadow: 0 0 7px #373d49;
}

.nav_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0px 15px 0px;
}

.nav_container a {
    color: white;
}


.logo_container {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.logo_container>a {
    color: white;
    font-size: 1.4rem;
    font-weight: 500;
    display: inline-block;
}

.collapse {
    background: transparent;
    font-size: 26px;
    color: #9e9e9e;
    display: none;
    cursor: pointer;
}

.collapse:hover {
    color: #ebebf5;
}

.collapse_icon {
    display: flex;
    text-align: center;
}

.mobilNavigation {
    display: none;
}

.menu_container {
    flex-grow: 0.1;
}

.menu_container ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 1rem;

}

.menu_container ul>li {
    margin-left: 8px;
    padding: 5px 8px 5px 8px;
    border-bottom: 2px solid transparent;
    transition: var(--trasition);
}

.navigation>ul>li>a {
    transition: 0.2s all;
}

.navigation>ul>li>a:hover {
    color: #6542e0;
    border-bottom: 3px solid #6542e0;
}

.arrow-icon {
    font-size: 22px;
    margin-left: 2px;
    transition: all 400ms ease;
}

#servi-list:hover {
    border-bottom: none;
    cursor: default;
}

.flag {
    width: 30px;
    height: 30px;
}

.lenguaje-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: 20px;
}

.select-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border: 1px solid white;
    border-radius: 4px;
    padding: 1px 5px 1px 5px;
    cursor: pointer;
    transition: all 400ms ease;
}

.list-lang {
    position: absolute;
    background: #6542e0;
    top: 60px;
    padding: 4px;
    min-width: 70px;
    border-radius: 4px;
}

.list-lang li {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 3px 0px 3px 0px;
}

.list-lang li:hover {
    background: #5437bc;
}

.option-text {
    margin-left: 8px;
}

.disabled {
    display: none;
}


/* ============ Media queries (Medium devices) =============== */
@media screen and (max-width: 935px) {
    .collapse {
        display: block;
    }

    .navigation {
        display: none;
    }

    .mobilNavigation {
        display: flex;
        align-items: center;
    }

    .mobilNavigation ul {
        background-color: #6542e0;
        color: white;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 72px;
        right: 0;
        width: 100%;
        padding: 10px 0 10px 0;
        box-shadow: 0px 0px 5px var(--color-bg-variant);
        z-index: 1;
        z-index: 11;
    }

    .mobilNavigation ul li {
        padding: 5px 0 5px 10%;
        margin-left: 0px;
    }

    .mobilNavigation ul li:hover {
        background: #5437bc;
        margin-left: 8px;

    }

    .drop-menu:hover #servi-list {
        color: #fff;
    }

    .drop-menu:hover .arrow-icon {
        color: #fff;
    }

    .lenguaje-container {
        display: none;
    }

}