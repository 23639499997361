.members-home {
    padding: 70px 0;
    color: #424141;
    /* background-color: #f2f7f8; */
    background-color: #ffffff;
}

.members-home h2 {
    text-align: center;
    margin-bottom: 70px;
}

.members-home h2::after {
    content: "";
    display: block;
    width: 200px;
    height: 3px;
    background-color: rgb(101, 66, 224, 0.7);
    margin: 0 auto;
}

.members-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 4rem;
}

.img-members {
    border-radius: 15px;
    padding: 0 10px;
    height: 120px;
    transition: 0.4s all;  
}

.img-members:hover {
    cursor: pointer;
    box-shadow: 2px 2px 10px #ccc;
}

.img-members img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    text-shadow: 2px 2px 2px #000;
}