
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0px;
    padding: 0px;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}


:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --container-width-lg: 70%;
    --container-width-md: 85%;
    --container-width-sm: 90%;

    --trasition: all 400ms ease;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Poppins', sans-serif;
    background: white;
    color: var(--color-white);
    line-height: 1.7;    
}

/* ============ GENERAL STYLES ========= */
.container {
    width: var(--container-width-lg);
    max-width: 1150px;
    margin: 0px auto;
}

h1,h2,h3,h4,h5 {
    font-weight: 700;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    /* font-size: 2.1rem; */
    font-size: clamp(1.6rem, 6vw, 2.1rem);
}

p {
    font-size: 1.1rem;
    color: #6d7584;
    line-height: 1.5;
}

@media screen and (max-width: 420px) {
    .container {
        width: var(--container-width-sm);
    }
}